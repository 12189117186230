import autobind from 'autobind-decorator'
import React, { Component } from 'react'
import { Auth0Authentication } from '../../auth/Auth0Authentication'
import { ReactComponent as LoginButton } from './login.svg'
import Spotify from 'spotify-web-api-js'

export interface HomeProps {
    auth: Auth0Authentication
}

let song_1_default_class = "card song song1"
let song_2_default_class = "card song song2"
let fadeAudioInSong1
let fadeAudioOutSong1
let fadeAudioInSong2
let fadeAudioOutSong2
let ignore_volume = false

export default class Home extends Component<HomeProps, {}> {
    constructor(HomeProps) {
        super(HomeProps)
        this.state = {
            is_touch: false,
            is_muted: false,
            is_choosing: false,
            container_class: "container intro",
            show_help: false,
            rounds_class: "rounds",
            artist_id: "4Ndc5Hb4h3dBftO9xd35vO",
            current_round: 1,
            total_rounds: 1,
            battle_commenced: false,
            tracks: [],
            artists: [],
            artist_name: 'Take to the Seas',
            artist_img: 'https://i.scdn.co/image/ab6761610000e5ebaa094d0778a779d727887da3',
            song_1: false,
            song_2: false,
            song_1_is_playing: false,
            song_2_is_playing: false,
            song_1_class: song_1_default_class,
            song_2_class: song_2_default_class,
            load_song_1: false,
            load_song_2: false,
            share_link_facebook: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftaketotheseas.com",
            share_link_twitter: "https://twitter.com/intent/tweet?url=https%3A%2F%2Ftaketotheseas.com&text=Takes%20to%20the%20Seas%20-%20Make%20Your%20Bones%20is%2010!%20What's%20your%20favourite%20track%3F!%20Play%20now!"
        }
    }

    componentDidMount() {
        let _this = this

        setTimeout(function () {
            _this.setState({ container_class: "container" })
        }, 500)

        _this.chooseArtist(_this.state.artist_id)
    }

    @autobind
    login() {
        this.props.auth.login()
    }

    @autobind
    logout() {
        this.props.auth.logout()
    }

    @autobind
    chooseArtist(artist_id, index = -1) {
        let _this = this

        // Reset everything...
        _this.setState({ 
            battle_commenced: false,
            container_class: "container",
            rounds_class: "rounds"
        })

        // Get tracks
        if (_this.props.auth && _this.props.auth.authenticated && artist_id) {
            var spotifyApi = new Spotify()
            spotifyApi.setAccessToken(_this.props.auth.accessToken)

            // TODO: Get user country... we need user-read-private for this...
            spotifyApi.getArtistTopTracks(artist_id, "GB").then(
                function (data) {
                    _this.setState({
                        artist_id: artist_id,
                        tracks: data.tracks.reverse()
                    })

                    // Update artists info
                    if (index > -1) {
                        let artist = _this.state.artists[index]

                        _this.setState({
                            artist_name: artist.name,
                            // TODO: Default image...
                            artist_img: artist.images[0].url
                        })
                    }
                },
                function (err) {
                    console.log(err)
                    _this.props.auth.logout()
                }
            )
        }
    }

    @autobind
    startBattle() {
        let _this = this

        _this.setState({ container_class: "container starting" })

        // TODO: We assume we've got tracks?
        if (_this.state.tracks !== []) {
            // Set rounds
            _this.setState({
                current_round: 1,
                total_rounds: (_this.state.tracks.length - 1 > 0) ? _this.state.tracks.length - 1 : 1
            })

            // Populate the first two songs
            _this.setSong(_this.state.tracks[0], 1)
            _this.setSong(_this.state.tracks[1], 2)

            setTimeout(function () {
                _this.setState({ 
                    battle_commenced: true,
                    container_class: "container versus",
                    rounds_class: "rounds visible"
                })
            }, 500)
        }
        else {
            console.log('No tracks...')
            _this.props.auth.logout()
        }
    }

    @autobind
    setTouched() {
        let _this = this

        _this.setState({ is_touch: true })
    }

    @autobind
    toggleHelp() {
        let _this = this

        _this.setState({ show_help: !_this.state.show_help })

        // TODO: Scroll to top of help section
        // window.scrollTo(0,0);

        window.scrollTo(0,0);
    }

    @autobind
    toggleSound() {
        let _this = this

        // TODO: Stop all audio
        let audio1 = _this.refs.song1Audio
        if (audio1) {
            audio1.muted = !_this.state.is_muted
        }

        let audio2 = _this.refs.song2Audio
        if (audio2) {
            audio2.muted = !_this.state.is_muted
        }

        _this.setState({ is_muted: !_this.state.is_muted })
    }

    @autobind
    getTopArtists() {
        let _this = this

        if (_this.props.auth && _this.props.auth.authenticated) {
            var spotifyApi = new Spotify()

            spotifyApi.getMyTopArtists({
                limit: 5
            }).then(
                function (data) {
                    _this.setState({
                        artists: data.items
                    })
                },
                function (err) {
                    console.log(err)
                }
            )
        }
    }

    @autobind
    chooseSong(song) {
        let _this = this
        let is_playing = false

        if (_this.state.battle_commenced === true && _this.state.is_choosing === false) {
            if (song === 1) {
                if (_this.state.song_1_is_playing === true || _this.state.is_muted === true) {
                    is_playing = true

                    _this.setState({
                        song_1_class: _this.state.song_1_class + " selected",
                        song_2_class: _this.state.song_2_class + " defeated",
                        load_song_2: true
                    })
                }
                else {
                    _this.playAudio(song, true)
                    _this.setState({ 
                        song_1_class: song_1_default_class + " active",
                        song_1_is_playing: true
                    })
                }
            }
            else {
                if (_this.state.song_2_is_playing === true || _this.state.is_muted === true) {
                    is_playing = true

                    _this.setState({ 
                        song_1_class: _this.state.song_1_class + " defeated",
                        song_2_class: _this.state.song_2_class + " selected",
                        load_song_1: true
                    })
                }
                else {
                    _this.playAudio(song, true)
                    _this.setState({
                        song_2_class: song_2_default_class + " active",
                        song_2_is_playing: true
                    })
                }
            }
    
            if (is_playing) {
                _this.setState({ is_choosing: true })

                setTimeout(function () {
                    // Swipe off screen
                    _this.setState({ container_class: "container versus selection" + song })
        
                    // Remove classes for winner/loser
                    _this.setState({ song_1_class: _this.state.song_1_class.replace("selected", "").replace("defeated", "") })
                    _this.setState({ song_2_class: _this.state.song_2_class.replace("selected", "").replace("defeated", "") })

                    if (_this.state.is_touch === true) {
                        _this.pauseAudio(song)
                    }
        
                    if (_this.state.current_round < _this.state.total_rounds) {
                        // Update current round
                        _this.setState({ current_round: _this.state.current_round + 1 })
    
                        // Update song
                        _this.setSong(_this.state.tracks[_this.state.current_round], (song === 1 ? 2 : 1))
        
                        // Bring it back on
                        setTimeout(function () {
                            _this.setState({ container_class: "container versus" })
                        }, 800)
                    }
                    else {
                        _this.setState({ battle_commenced: false })
                        // Declare winner!
                        setTimeout(function () {
                            _this.setState({ 
                                container_class: "container played versus selection" + song + " winner" + song,
                                rounds_class: "rounds",
                                load_song_1: true,
                                load_song_2: true
                            })
                            
                            // getRelatedArtists(artist_id)

                            _this.getTopArtists()
                        }, 800)
                    }

                    _this.setState({ is_choosing: false })
                }, 800)
            }
        }
    }

    @autobind
    playAudio(song, override_touch = false) {
        let _this = this

        if (!_this.state.is_muted && _this.state.battle_commenced === true) {
            if (!_this.state.is_touch || override_touch) {
                if (song === 1) {
                    _this.setState({
                        song_1_class: song_1_default_class + " active",
                        song_1_is_playing: true,
                        load_song_1: false
                    })
        
                    if (typeof fadeAudioOutSong1 !== 'undefined') {
                        clearInterval(fadeAudioOutSong1)
                    }
            
                    let audio = _this.refs.song1Audio
            
                    if (audio) {

                        // Only load audio if new song
                        if (_this.state.load_song_1 === true) {
                            audio.load()
                        }
                        
                        audio.volume = 0
                        audio.play()

                        let new_volume = 1
                        let last_volume = audio.volume

                        fadeAudioInSong1 = setInterval(function () {
                            if (audio.volume < 1.0 && last_volume !== new_volume) {
                                last_volume = audio.volume
                                new_volume = parseFloat((audio.volume + 0.2).toFixed(1))
                                audio.volume = new_volume <= 1 ? new_volume : 1
                            }
                            else {
                                clearInterval(fadeAudioInSong1)
                            }
                        }, 200)
                    }
                }
                else if (song === 2) {
                    _this.setState({
                        song_2_is_playing: true,
                        song_2_class: song_2_default_class + " active",
                        load_song_2: false
                    })
        
                    if (typeof fadeAudioOutSong2 !== 'undefined') {
                        clearInterval(fadeAudioOutSong2)
                    }
            
                    let audio = _this.refs.song2Audio
            
                    if (audio) {
                        // Only load audio if new song
                        if (_this.state.load_song_2 === true) {
                            audio.load()
                        }
                        
                        audio.volume = 0
                        audio.play()

                        let new_volume = 1
                        let last_volume = audio.volume

                        fadeAudioInSong2 = setInterval(function () {
                            if (audio.volume < 1.0 && last_volume !== new_volume) {
                                last_volume = audio.volume
                                new_volume = parseFloat((audio.volume + 0.2).toFixed(1))
                                audio.volume = new_volume <= 1 ? new_volume : 1
                            }
                            else {
                                clearInterval(fadeAudioInSong2)
                            }
                        }, 200)
                    }
                }
            }
        }
    }

    pauseAudio(song) {
        let _this = this

        setTimeout(function () {
            if (song === 1) {
                _this.setState({
                    song_1_is_playing: false,
                    song_1_class: _this.state.song_1_class.replace("active", "")
                })

                if (typeof fadeAudioInSong1 !== 'undefined') {
                    clearInterval(fadeAudioInSong1)
                }
        
                let audio = _this.refs.song1Audio
        
                if (audio) {
                    let new_volume = 0
                    ignore_volume = false

                    audio.addEventListener('volumechange', (event) => {
                        ignore_volume = false
                    });

                    fadeAudioOutSong1 = setInterval(function () {
                        if (audio.volume > 0.0 && ignore_volume === false) {
                            new_volume = parseFloat((audio.volume - 0.1).toFixed(1))
                            audio.volume = new_volume >= 0 ? new_volume : 0
                            ignore_volume = true
                        }
                        else {
                            clearInterval(fadeAudioOutSong1)
                            audio.pause()
                        }
                    }, 20)
                }
            }
            else if (song === 2) {
                _this.setState({
                    song_2_is_playing: false,
                    song_2_class: _this.state.song_2_class.replace("active", "")
                })

                if (typeof fadeAudioInSong2 !== 'undefined') {
                    clearInterval(fadeAudioInSong2)
                }
        
                let audio = _this.refs.song2Audio
        
                if (audio) {
                    let new_volume = 0
                    ignore_volume = false

                    audio.addEventListener('volumechange', (event) => {
                        ignore_volume = false
                    });

                    fadeAudioOutSong2 = setInterval(function () {
                        if (audio.volume > 0.0 && ignore_volume === false) {
                            new_volume = parseFloat((audio.volume - 0.1).toFixed(1))
                            audio.volume = new_volume >= 0 ? new_volume : 0
                            ignore_volume = true
                        }
                        else {
                            clearInterval(fadeAudioOutSong2)
                            audio.pause()
                        }
                    }, 20)
                }
            }
        }, 200)
    }

    setSong(track, song) {
        let _this = this

        if (song === 1) {
            _this.setState({
                song_1: {
                    'title': track.name,
                    // TODO: Default image
                    'src': 'https://i.scdn.co/image/ab6761610000e5ebaa094d0778a779d727887da3',
                    'preview_url': track.preview_url
                }
            })

            if (track.album && track.album.images.length > 0) {
                _this.setState({
                    song_1: {
                        'title': track.name,
                        'src': track.album.images[0].url,
                        'preview_url': track.preview_url
                    }
                })
            }
        }
        else if (song === 2) {
            _this.setState({
                song_2: {
                    'title': track.name,
                    // TODO: Default image
                    'src': 'https://i.scdn.co/image/ab6761610000e5ebaa094d0778a779d727887da3',
                    'preview_url': track.preview_url
                }
            })

            if (track.album && track.album.images.length > 0) {
                _this.setState({
                    song_2: {
                        'title': track.name,
                        'src': track.album.images[0].url,
                        'preview_url': track.preview_url
                    }
                })
            }
        }
    }

    render() {
        const { authenticated } = this.props.auth
        return (
            <div className={this.state.container_class + (this.state.show_help ? " help" : "") }>
                <div className="battle">
                    <div>
                        {!authenticated && (
                            <h1><span>Take</span> <span>to the </span> <span>Seas</span></h1>
                        )}
                        {!authenticated && (
                            <h2 className="battle_artist">Make Your Bones</h2>
                        )}

                        {authenticated && (
                            <h1 className="song_battle"><span>Song</span> <span>Battle</span></h1>
                        )}
                        {authenticated && (
                            <h2 className="battle_artist">{this.state.artist_name}</h2>
                        )}
                        {authenticated && (
                            <h3 className={this.state.rounds_class}>Round {this.state.current_round} of {this.state.total_rounds}</h3>
                        )}
                    </div>

                    {authenticated && (
                        <ul className="artists visible">
                            <li className="card artist" ref="artist">
                                <div className="front">
                                    <img src={this.state.artist_img} alt="" />
                                </div>
                                <div className="back">VS</div>
                            </li>
                        </ul>
                    )}

                    {!authenticated && (
                        <ul className="artists artists--myb">
                            <li className="card artist" ref="artist">
                                <div className="front">
                                    <img src={this.state.artist_img} alt="" />
                                </div>
                            </li>
                        </ul>
                    )}

                    {this.state.artists.length > 0 && (
                        <div className="related visible">
                            <ul id="relatedItems">
                                {this.state.artists.map((item, index) => 
                                    <li key={item.id} className="card artist" onClick={(() => this.chooseArtist(item.id, index))}>
                                        <div>
                                            <div className="front">
                                                {item.images.length > 0 && (
                                                    <img src={item.images[0].url} alt="" />
                                                )}
                                            </div>
                                            <div className="info">{item.name}</div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}

                    <div className={authenticated ? "play play--small" : "play"}>
                        {authenticated && (
                            <div>
                                <button className="btn btn--start visible" onClick={this.startBattle}>Play now</button>

                                <div className="social_share">
                                    <div>
                                        Share
                                        <a href={this.state.share_link_facebook} target="_blank" rel="noreferrer">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg>
                                        </a>
                                        <a href={this.state.share_link_twitter} target="_blank" rel="noreferrer">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"></path></svg>
                                        </a>
                                        <a href="https://open.spotify.com/album/3zELtBMH2vylCBygzL6Ay9">
                                            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"></path></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!authenticated && (
                            <div>
                                <LoginButton className="btn--auth" fill="#1DB954" onClick={this.login} />
                                <button className="btn btn--link" onClick={(() => this.toggleHelp())}>What is this?</button>
                            </div>
                        )}
                    </div>
                </div>

                {this.state.song_1 && this.state.song_2 && (
                    <ul className="songs">
                        <li className={this.state.song_1_class}
                            onClick={(() => this.chooseSong(1))}
                            onMouseEnter={() => this.playAudio(1)}
                            onMouseLeave={() => this.pauseAudio(1)}
                            onTouchStart={() => this.setTouched()}>
                            <div className="front">
                                <h2 className="song__title">{this.state.song_1.title}</h2>
                                <img className="artwork" src={this.state.song_1.src} alt="" />

                                {!this.state.is_muted && (
                                    <img className="action" src="img/play.svg" alt="" />
                                )}

                                {this.state.song_1.preview_url ?
                                (
                                    <div>
                                        <audio ref="song1Audio">
                                            <source src={this.state.song_1.preview_url} />
                                        </audio>
                                    </div>
                                ) : (<div></div>)}
                            </div>
                            <div className="back">
                                <img src="img/times.svg" alt="" />
                            </div>
                        </li>

                        <li className={this.state.song_2_class}
                            onClick={(() => this.chooseSong(2))}
                            onMouseEnter={() => this.playAudio(2)}
                            onMouseLeave={() => this.pauseAudio(2)}
                            onTouchStart={() => this.setTouched()}
                            >
                            <div className="front">
                                <h2 className="song__title">{this.state.song_2.title}</h2>
                                <img className="artwork" src={this.state.song_2.src} alt="" />
                                
                                {!this.state.is_muted && (
                                    <img className="action" src="img/play.svg" alt="" />
                                )}

                                {this.state.song_2.preview_url ?
                                (
                                    <div>
                                        <audio ref="song2Audio">
                                            <source src={this.state.song_2.preview_url} />
                                        </audio>
                                    </div>
                                ) : (<div></div>)}
                            </div>
                            <div className="back">
                                <img src="img/times.svg" alt="" />
                            </div>
                        </li>
                    </ul>
                )}

                <div className="help_section">
                    <div className="help_section_container">
                        <h1><span>Take</span> <span>to the</span> <span>Seas</span></h1>
                        <h2>Song Battle</h2>
                        
                        <p>To celebrate 10 years of Make Your Bones, we've created something you never knew you needed...</p>
                        <p>Song Battle pits 10 tracks against each other. You decide which one wins!</p>
                        
                        <h2>How does it work?</h2>
                        <p>We take an artist's top 10 songs and you choose your favourite.</p>
                        <p><strong>On mobile/tablet:</strong> Tap a song to play it. Tap it again to select your favourite.</p>
                        <p><strong>On desktop:</strong> Hover over a song to play it. Click to select your favourite.</p>
                        <p>Repeat until you have a winner!</p>
                        <p>You can mute at any time in the bottom left corner.</p>

                        <h2>Log in with Spotify</h2>
                        <p>This site does not save any Spotify data. When you log in with Spotify, the site can read your top artists while you're playing so we can help you procrastinate a little bit longer.</p>
                        <p>You can remove this access for “Take to the Seas - Song Battle” at any time on Spotify's 3rd Party app page <a href="https://www.spotify.com/account/apps/" target="_blank" rel="noreferrer">here</a>.</p>

                        <h2>Who are Take to the Seas?!</h2>
                        <p>Check them out on <a href="https://open.spotify.com/album/3zELtBMH2vylCBygzL6Ay9" target="_blank" rel="noreferrer">Spotify</a>, <a href="https://music.apple.com/gb/album/make-your-bones/467361841" target="_blank" rel="noreferrer">iTunes</a> and <a href="https://www.amazon.co.uk/Make-Your-Bones/dp/B005OVMS8C" target="_blank" rel="noreferrer">Amazon</a>.</p>

                        <p>It's time to re-discover some indie-pop bangers! What's your favourite track!?</p>

                        <div className="share">
                            <a href="https://open.spotify.com/album/3zELtBMH2vylCBygzL6Ay9" target="_blank" rel="noreferrer">
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"></path></svg>
                            </a>
                            <a href="https://music.apple.com/gb/album/make-your-bones/467361841" target="_blank" rel="noreferrer">
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M223.6 80.3C129 80.3 52.5 157 52.5 251.5S129 422.8 223.6 422.8s171.2-76.7 171.2-171.2c0-94.6-76.7-171.3-171.2-171.3zm79.4 240c-3.2 13.6-13.5 21.2-27.3 23.8-12.1 2.2-22.2 2.8-31.9-5-11.8-10-12-26.4-1.4-36.8 8.4-8 20.3-9.6 38-12.8 3-.5 5.6-1.2 7.7-3.7 3.2-3.6 2.2-2 2.2-80.8 0-5.6-2.7-7.1-8.4-6.1-4 .7-91.9 17.1-91.9 17.1-5 1.1-6.7 2.6-6.7 8.3 0 116.1.5 110.8-1.2 118.5-2.1 9-7.6 15.8-14.9 19.6-8.3 4.6-23.4 6.6-31.4 5.2-21.4-4-28.9-28.7-14.4-42.9 8.4-8 20.3-9.6 38-12.8 3-.5 5.6-1.2 7.7-3.7 5-5.7.9-127 2.6-133.7.4-2.6 1.5-4.8 3.5-6.4 2.1-1.7 5.8-2.7 6.7-2.7 101-19 113.3-21.4 115.1-21.4 5.7-.4 9 3 9 8.7-.1 170.6.4 161.4-1 167.6zM345.2 32H102.8C45.9 32 0 77.9 0 134.8v242.4C0 434.1 45.9 480 102.8 480h242.4c57 0 102.8-45.9 102.8-102.8V134.8C448 77.9 402.1 32 345.2 32zM223.6 444c-106.3 0-192.5-86.2-192.5-192.5S117.3 59 223.6 59s192.5 86.2 192.5 192.5S329.9 444 223.6 444z"></path></svg>
                            </a>
                            <a href="https://www.amazon.co.uk/Make-Your-Bones/dp/B005OVMS8C" target="_blank" rel="noreferrer">
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z"></path></svg>
                            </a>
                        </div>
                    </div>
                    <button className="close_btn" onClick={(() => this.toggleHelp())}><img src="img/times.svg" alt="Close help" /></button>
                </div>

                <button className="help_btn" onClick={(() => this.toggleHelp())}><img src="img/question.svg" alt="Open help" /></button>

                {this.state.is_muted
                    ? <button className="volume_btn volume_btn--mute" onClick={(() => this.toggleSound())}><img src="img/mute.svg" alt="Unmute" /></button>
                    : <button className="volume_btn" onClick={(() => this.toggleSound())}><img src="img/unmute.svg" alt="Mute" /></button>
                }
            </div>
        )
    }
}